import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hello Everyone! I am <span className="green">Drew Lauhon </span>
            from <span className="green"> Huntington, West Virginia.</span>
            <br /> I am a Full-Stack Engineer that graduated from Marshall University with a major in <span className="green">Computer Information Technology. </span>
            I emphasized in <span className="green">Web and Application Development</span> while also earning a minor in <span className="green">Entrepreneurship.</span>
            <br />
           <br />
            Additionally, I am currently employed as the <span className="green">Health Science Web Strategy Director</span> at
            Marshall University/Marshall Health.
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Listening to lots of music
            </li>
            <li className="about-activity">
              <ImPointRight /> Writing songs
            </li>
            <li className="about-activity">
              <ImPointRight /> Playing games
            </li>
          </ul>

          <p style={{ color: "rgb(190 210 180)" }}>
            "Around here, however, we don't look backwards for very long. We keep moving forward, opening up new doors and doing new things, because we're curious...and curiosity keeps leading us down new paths."{" "}
          </p>
          <footer className="blockquote-footer">Walt Disney</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
