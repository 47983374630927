import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

import mhealth from "../../Assets/Projects/mhealth.png";
import blossd from "../../Assets/Projects/bnd.png";
import hbloom from "../../Assets/Projects/hbloom.png";
import keithalbee from "../../Assets/Projects/kalbee.png";
import tylertruss from "../../Assets/Projects/ttruss.png";
import redesign from "../../Assets/Projects/redesign-new.png";
import intranet from "../../Assets/Projects/intranet.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My <strong className="purple">Project Portfolio </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={redesign}
             
              title="Marshall Health Intranet Redesign (Coming Soon)"
              description="Collaborated design for the new Marshall Health + Marshall School of Medicine internal Intranet for various resources and information.
              This is a mockup currently but also being designed and built as well. It is being moved to a WordPress framework, incorporating APIs and other plugins. 
              I started building the site and will see the site to launch in the coming months. Link coming soon!"
              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={mhealth}
              isBlog={false}
              title="Marshall Health"
              description="Built using a framework called Umbraco, which is .NET based. Built many years ago, but handed over to me for upkeep and constant maintanence.
              Incorporating new APIs occasionally and updating visual looks like new site-wide navigation and embedding other sites within this one."
              demoLink="https://marshallhealth.org/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={intranet}
              isBlog={false}
              title="Current Marshall Health Intranet (Private Login)"
              description="The current Marshall Health + Marshall School of Medicine internal Intranet. Built from a framework called Umbraco, which is .NET based. Straightforward
              site just containing information and important links for resident students and faculty. Protected by an IP checked login for on-site or verified off-site users."
              demoLink="https://intranet.marshallhealth.org/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={blossd}
              isBlog={false}
              title="Bloss & Dillard"
              description="Website built through an ad agency using WordPress, APIs, PHP, and Javascript injections. Paired with this, was maintaining a intranet for the company
              using Docker, Laravel, and AWS services for documents and network protection. "
              demoLink="https://bloss-dillard.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={tylertruss}
              isBlog={false}
              title="Tyler Truss Systems"
              description="Built using WordPress, WooCommerce, Javascript injections and advanced CSS methods. Site framework was built before being handed over to me.
              I finished the website and launched the site for the company. Also paired with this was a site built for another section of the company which used similar styles and frameworks."
              demoLink="https://tylertruss.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={keithalbee}
              isBlog={false}
              title="Keith-Albee"
              description="Built using WordPress, Javascript injections, and advanced CSS methods. Many custom elements built for a strictly informational site, as well as
              incorporating a donation system. There was also an event tracking system for informing the public of new events and keeping them listed on the website through a custom made slider."
              demoLink="https://keithalbee.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={hbloom}
              isBlog={false}
              title="Huntington In Bloom"
              description="Built from a template system through WordPress. Incorporated custom forms, a donation system, and other animated styles. There is custom PHP present
              on the site as well as advanced CSS and moderate amounts of Javascript riddled throughout. Built through an ad agency for the city of Huntington, WV."
              demoLink="https://huntingtoninbloom.org/" 
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
