import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/d-avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
  AiOutlineMail,
} from "react-icons/ai";
import {
  RiTwitterXLine,
} from "react-icons/ri";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              ALLOW ME TO <span className="green"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              I started programming in high school, fell in love with it... and haven't looked back!
              <br />
              <br />I know languages like <i><b className="green">PHP, React, Swift, SQL</b></i> - among others.
              
              <br />
              <br />
              My interests are building new&nbsp;
              <i>
                <b className="green">Websites, Designs, User-Experiences </b> and
                also in areas related to{" "}
                <b className="green">
                  Apple.
                </b>
              </i>
              <br />
              <br />
              Whenever possible, I use my interests to dive deeper into <b className="green">Node.js, Vercel</b> and
              <i>
                <b className="green">
                  {" "}
                  Modern Javascript Libraries/Frameworks
                </b>
              </i>
              &nbsp; like
              <i>
                <b className="green"> React.js and Next.js.</b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
            these social networks to <span className="green">connect </span>with me!
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/dlauhon"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://x.com/drewlauhon"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <RiTwitterXLine />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/drew-lauhon/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>

              <li className="social-icons">
                <a
                  href="mailto:drewlauhon@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineMail />
                </a>
              </li>
              
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
